import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// Company Exist
export const companyExist = createAsyncThunk(
  "companyExist",
  async ({ id, company_name }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `company-exist?name=${company_name}&id=${id}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Companies List
export const companiesList = createAsyncThunk(
  "companiesList",
  async (
    { search, kyc, status, tag_status, pageLimit, pageCount },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `companies?search=${search}&kyc_status=${kyc}&tag=${tag_status}&status=${status}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Business Activities
export const businessActivities = createAsyncThunk(
  "businessActivities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`business-activities`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Address
export const Address = createAsyncThunk(
  "Address",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`registered_address`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Create Address
export const createAddress = createAsyncThunk(
  "createAddress",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`address`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// employment pass
export const employmentPass = createAsyncThunk(
  "employmentPass",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`employment-details`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);
// Employment Support
export const employmentSupport = createAsyncThunk(
  "employmentSupport",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`companies/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message || error.response.data.error);
      return rejectWithValue(error.response.data || error.response.data.error);
    }
  }
);

// Company Data
export const companyData = createAsyncThunk(
  "companyData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`company-data`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Company
export const CreateCompany = createAsyncThunk(
  "CreateCompany",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`companies`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update Company
export const updateCompany = createAsyncThunk(
  "updateCompany",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`companies/${id}`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Director / Shareholder
export const DeleteDirectorShareholder = createAsyncThunk(
  "DeleteDirectorShareholder",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`users/${id}`);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Existing Users
export const existingUsers = createAsyncThunk(
  "existingUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`existing-users`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Director / Shareholder
export const addDirectorShareholder = createAsyncThunk(
  "addDirectorShareholder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`users`, data);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Nominee Directors Details
export const getNomineeDirectorsDetails = createAsyncThunk(
  "getNomineeDirectorsDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=nominee_director`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Corporate Secretary
export const getCorporateSecretary = createAsyncThunk(
  "corporate_secretary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?slug=corporate_secretary`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Get Company By ID
export const getCompanyById = createAsyncThunk(
  "getCompanyById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`companies/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get Sales By Company Id
export const getSalesByCompanyId = createAsyncThunk(
  "getSalesByCompanyId",
  async ({ id, sub_type, year, pageLimit, pageCount }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(
        `transactions?company_id=${id}&type=Company&sub_type=${sub_type}&year=${year}&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Get transactions By ID
export const getTransactionsById = createAsyncThunk(
  "getTransactionsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`transactions/${id}`);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialStates,
  extraReducers: {
    // Company Exist
    [companyExist.pending]: (state) => {
      state.companyExistStatus.loading = true;
    },
    [companyExist.fulfilled]: (state, action) => {
      state.companyExistStatus.loading = false;
      state.companyExistStatus.data = action.payload;
      state.error = false;
    },
    [companyExist.rejected]: (state) => {
      state.companyExistStatus.loading = false;
      state.error = true;
    },
    // companies
    [companiesList.pending]: (state) => {
      state.loading = true;
    },
    [companiesList.fulfilled]: (state, action) => {
      state.loading = false;
      state.companiesListStatus.data = action.payload;
      state.error = false;
    },
    [companiesList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Business Activities
    [businessActivities.pending]: (state) => {
      state.businessActivitiesStatus.loading = true;
    },
    [businessActivities.fulfilled]: (state, action) => {
      state.businessActivitiesStatus.loading = false;
      state.businessActivitiesStatus.data = action.payload;
      state.error = false;
    },
    [businessActivities.rejected]: (state) => {
      state.businessActivitiesStatus.loading = false;
      state.error = true;
    },
    // Address
    [Address.pending]: (state) => {
      state.loading = true;
    },
    [Address.fulfilled]: (state, action) => {
      state.loading = false;
      state.AddressStatus.data = action.payload;
      state.error = false;
    },
    [Address.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // employment Pass
    [employmentPass.pending]: (state) => {
      state.loading = true;
    },
    [employmentPass.fulfilled]: (state, action) => {
      state.loading = false;
      state.employmentPassStatus.data = action.payload;
      state.error = false;
    },
    [employmentPass.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // employment Support
    [employmentSupport.pending]: (state) => {
      state.loading = true;
    },
    [employmentSupport.fulfilled]: (state, action) => {
      state.loading = false;
      state.employmentSupportStatus.data = action.payload;
      state.error = false;
    },
    [employmentSupport.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Company Data
    [companyData.pending]: (state) => {
      state.loading = true;
    },
    [companyData.fulfilled]: (state, action) => {
      state.loading = false;
      state.companyDataStatus.data = action.payload;
      state.error = false;
    },
    [companyData.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Create Company
    [CreateCompany.pending]: (state) => {
      state.loading = true;
    },
    [CreateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.CreateCompanyStatus.data = action.payload;
      state.error = false;
    },
    [CreateCompany.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // update Company
    [updateCompany.pending]: (state) => {
      state.loading = true;
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateCompanyStatus.data = action.payload;
      state.error = false;
    },
    [updateCompany.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // Delete Director Shareholder
    [DeleteDirectorShareholder.pending]: (state) => {
      state.loading = true;
    },
    [DeleteDirectorShareholder.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [DeleteDirectorShareholder.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Existing Users
    [existingUsers.pending]: (state) => {
      state.existingUsersStatus.loading = true;
    },
    [existingUsers.fulfilled]: (state, action) => {
      state.existingUsersStatus.loading = false;
      state.existingUsersStatus.data = action.payload;
      state.error = false;
    },
    [existingUsers.rejected]: (state) => {
      state.existingUsersStatus.loading = false;
      state.error = true;
    },
    // Add Director Shareholder
    [addDirectorShareholder.pending]: (state) => {
      state.addDirectorShareholderStatus.loading = true;
    },
    [addDirectorShareholder.fulfilled]: (state, action) => {
      state.addDirectorShareholderStatus.loading = false;
      state.addDirectorShareholderStatus.data = action.payload;
      state.error = false;
    },
    [addDirectorShareholder.rejected]: (state) => {
      state.addDirectorShareholderStatus.loading = false;
      state.error = true;
    },
    // Get Nominee Directors Details
    [getNomineeDirectorsDetails.pending]: (state) => {
      state.loading = true;
    },
    [getNomineeDirectorsDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.getNomineeDirectorsDetailsStatus.data = action.payload;
      state.error = false;
    },
    [getNomineeDirectorsDetails.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Corporate Secretary Details
    [getCorporateSecretary.pending]: (state) => {
      state.loading = true;
    },
    [getCorporateSecretary.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCorporateSecretaryStatus.data = action.payload;
      state.error = false;
    },
    [getCorporateSecretary.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Company By Id
    [getCompanyById.pending]: (state) => {
      state.loading = true;
    },
    [getCompanyById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getCompanyByIdStatus.data = action.payload;
      state.error = false;
    },
    [getCompanyById.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Get Sales By Company Id
    [getSalesByCompanyId.pending]: (state) => {
      state.getSalesByCompanyIdStatus.loading = true;
    },
    [getSalesByCompanyId.fulfilled]: (state, action) => {
      state.getSalesByCompanyIdStatus.loading = false;
      state.getSalesByCompanyIdStatus.data = action.payload;
      state.error = false;
    },
    [getSalesByCompanyId.rejected]: (state) => {
      state.getSalesByCompanyIdStatus.loading = false;
      state.error = true;
    },
    // Get transactions By ID
    [getTransactionsById.pending]: (state) => {
      state.loading = true;
    },
    [getTransactionsById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getTransactionsByIdStatus.data = action.payload;
      state.error = false;
    },
    [getTransactionsById.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});
const { reducer } = companiesSlice;
export default reducer;
