import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "./auth/slice";
import accountsSlice from "./accounts/slice";
import staffsSlice from "./staffs/slice";
import addressSlice from "./address/slice";
import masterSettingSlice from "./masterSetting/slice";
import companiesSlice from "./companies/slice";
import fileDataSlice from './folder&documents/slice';
import OrganisationSlice from './organisation/slice';

const rootReducer = {
  authData: authSlice,
  addressData: addressSlice,
  accountsData: accountsSlice,
  staffsData: staffsSlice,
  masterSettingData: masterSettingSlice,
  companiesData: companiesSlice,
  fileData: fileDataSlice,
  OrganisationData: OrganisationSlice
};

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: customizedMiddleware,
});

export default store;
