import React, { useState } from "react";
import ReactSelect from "react-select";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Close } from "../../../assets/svg/AllSvg";
import { InputWithLabel } from "../../../components/micro";
import { InputError } from "../../../commons/MicroComponents";
import { formats, modules } from "../../../commons/Editor";
import ImageResize from "quill-image-resize-module-react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const CreateQuickTicket = ({ setCreateQuickTicketModel }) => {
  const [maxCharacter, setMaxCharacter] = useState(0);
  const [confirmCreateTicket, setConfirmCreateTicket] = useState(false);
  const [createTicketData, setCreateTicketData] = useState({});
  Quill.register("modules/imageResize", ImageResize);

  const handleConfirmCreateTicket = () => {
    console.log(createTicketData);
    setConfirmCreateTicket(false);
  };

  return (
    <div
      className="model"
      onClick={() => {
        setCreateQuickTicketModel(false);
      }}
    >
      <div
        className="bg-white drop-shadow-lg rounded-lg w-full max-w-3xl min-h-36 py-5 px-6 mx-auto relative"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="text-theme w-full mb-1">Create Quick Ticket</p>
        <p className="text-sm text-light_grey mb-5">
          Write and address new queries and issues
        </p>
        <Close
          className="cursor-pointer absolute top-5 right-5"
          onClick={() => {
            setCreateQuickTicketModel(false);
          }}
        />
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              customer_name: "",
              customer_email: "",
              assign_staff: "",
              priority_status: "",
              ticket_title: "",
              message: "",
            }}
            validationSchema={Yup.object().shape({
              customer_name: Yup.object().required(
                "Please Select Customer Name"
              ),
              customer_email: Yup.object().required(
                "Please Enter Customer Email"
              ),
              assign_staff: Yup.object().required("Please Enter Assign Staff"),
              priority_status: Yup.object().required(
                "Please Enter Priority Status"
              ),
              ticket_title: Yup.string()
                .max(250)
                .required("Please Enter Ticket Title"),
              message: Yup.string().required("Please Enter Message"),
            })}
            onSubmit={(values) => {
              setCreateTicketData(values);
              setConfirmCreateTicket(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form className="w-full space-y-3" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-5 border-b border-secondary_grey pb-3">
                  {/* Customer Name */}
                  <div>
                    <ReactSelect
                      className={"text-black p-0"}
                      id="customer_name"
                      placeholder="Customer Name"
                      options={[
                        { label: "Customer One", value: "customer_one" },
                        { label: "Customer Teo", value: "customer_two" },
                      ]}
                      isLoading={false}
                      getOptionValue={(customer_name) => customer_name.value}
                      value={values.customer_name}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("customer_name", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.customer_name && touched.customer_name
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    {errors.customer_name && touched.customer_name ? (
                      <InputError errorTitle={errors.customer_name} />
                    ) : null}
                  </div>
                  {/* Customer Email */}
                  <div>
                    <ReactSelect
                      className={"text-black p-0"}
                      id="customer_email"
                      placeholder="Customer Email"
                      options={[
                        {
                          label: "customer1@gmail.com",
                          value: "customer1@gmail.com",
                        },
                        {
                          label: "customer2@gmail.com",
                          value: "customer2@gmail.com",
                        },
                      ]}
                      isLoading={false}
                      getOptionValue={(customer_email) => customer_email.value}
                      value={values.customer_email}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("customer_email", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.customer_email && touched.customer_email
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    {errors.customer_email && touched.customer_email ? (
                      <InputError errorTitle={errors.customer_email} />
                    ) : null}
                  </div>
                  {/* Assign Staff */}
                  <div>
                    <ReactSelect
                      className={"text-black p-0"}
                      id="assign_staff"
                      placeholder="Assign Staff"
                      options={[
                        { label: "customer1", value: "customer1" },
                        { label: "customer2", value: "customer2" },
                      ]}
                      isLoading={false}
                      getOptionValue={(assign_staff) => assign_staff.value}
                      value={values.assign_staff}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("assign_staff", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.assign_staff && touched.assign_staff
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    {errors.assign_staff && touched.assign_staff ? (
                      <InputError errorTitle={errors.assign_staff} />
                    ) : null}
                  </div>
                  {/* Priority Status */}
                  <div>
                    <ReactSelect
                      className={"text-black p-0"}
                      id="priority_status"
                      placeholder="Priority Status"
                      options={[{ label: "Normal", value: "normal" }]}
                      isLoading={false}
                      getOptionValue={(priority_status) =>
                        priority_status.value
                      }
                      value={values.priority_status}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("priority_status", e);
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: `1px solid ${
                            errors.priority_status && touched.priority_status
                              ? "red"
                              : "#0000003B"
                          }`,
                          borderRadius: "0.25rem",
                          padding: "6px 4px",
                          boxShadow: "none",
                          "&:hover": {
                            border: "1px solid #0000003B",
                          },
                        }),
                      }}
                    />
                    {errors.priority_status && touched.priority_status ? (
                      <InputError errorTitle={errors.priority_status} />
                    ) : null}
                  </div>
                  {/* Ticket Title */}
                  <div className="col-span-2">
                    <InputWithLabel
                      label={"Ticket Title"}
                      type={"text"}
                      className={
                        errors.ticket_title && touched.ticket_title
                          ? ` input-error `
                          : ` border border-secondary_grey`
                      }
                      idFromName="ticket_title"
                      onChange={(e) => {
                        handleChange(e);
                        setMaxCharacter(e.target.value.length);
                      }}
                      onBlur={handleBlur}
                      value={values.ticket_title}
                      errors={errors.ticket_title && touched.ticket_title}
                      errorsText={errors.ticket_title}
                    />
                    <span className="text-light_grey text-xs mt-0.5">
                      Max Character ({maxCharacter}/250)
                    </span>
                  </div>
                </div>
                <div>
                  <ReactQuill
                    id="message"
                    name="message"
                    onChange={handleChange("message")}
                    value={values.message}
                    modules={modules}
                    formats={formats}
                    bounds={"#root"}
                    placeholder={"Write something awesome..."}
                  />
                  {errors.message && touched.message ? (
                    <InputError errorTitle={errors.message} />
                  ) : null}
                </div>
                <div className="flex justify-end gap-4 w-full">
                  <button
                    type="button"
                    className="btn-outline uppercase"
                    onClick={() => {
                      setCreateQuickTicketModel(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn-theme uppercase">
                    Add
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {/* Confirm Create Ticket */}
      {confirmCreateTicket && (
        <div
          className="model"
          onClick={(e) => {
            setConfirmCreateTicket(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-md min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-[34px] font-ebGaramond mb-3">
              Confirm Create Ticket?
            </h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setConfirmCreateTicket(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  handleConfirmCreateTicket();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateQuickTicket;
