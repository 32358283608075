import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

// get Setting
export const getSetting = createAsyncThunk(
  "getSetting",
  async (
    { settingType, sub_type, pageLimit, pageCount },
    { rejectWithValue }
  ) => {
    try {
      const response = await AxiosApi.get(
        `master-settings?type=${settingType}&sub_type=${
          sub_type ?? ""
        }&page=${pageCount}&limit=${pageLimit}`
      );
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// get Master Settings by slug
export const getMasterSetting = createAsyncThunk(
  "getMasterSetting",
  async (slug, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`master-settings?slug=${slug}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// get Master Settings by id
export const getMasterSettingById = createAsyncThunk(
  "getMasterSettingById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`master-settings/${id}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// get Settings Type
export const getSettingsType = createAsyncThunk(
  "getSettingsType",
  async (type, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`get-settings-sub-type?type=${type}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Master Settings
export const updateMasterSetting = createAsyncThunk(
  "updateMasterSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`master-settings`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Update Remarks
export const updateSubscriptionRemarks = createAsyncThunk(
  "updateSubscriptionRemarks",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`master-settings/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
// Update Subscription Status
export const updateSubscriptionStatus = createAsyncThunk(
  "updateSubscriptionStatus",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`master-settings/${id}`, data);
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const masterSettingSlice = createSlice({
  name: "masterSetting",
  initialState: initialStates,
  extraReducers: {
    // get Setting
    [getSetting.pending]: (state) => {
      state.loading = true;
    },
    [getSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.getSettingStatus.data = action.payload;
      state.error = false;
    },
    [getSetting.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Master Setting By slug
    [getMasterSetting.pending]: (state) => {
      state.loading = true;
    },
    [getMasterSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.getMasterSettingStatus.data = action.payload;
      state.error = false;
    },
    [getMasterSetting.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Settings Type
    [getSettingsType.pending]: (state) => {
      state.loading = true;
    },
    [getSettingsType.fulfilled]: (state, action) => {
      state.loading = false;
      state.getSettingsTypeStatus.data = action.payload;
      state.error = false;
    },
    [getSettingsType.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // get Master Setting By ID
    [getMasterSettingById.pending]: (state) => {
      state.loading = true;
    },
    [getMasterSettingById.fulfilled]: (state, action) => {
      state.loading = false;
      state.getMasterSettingByIdStatus.data = action.payload;
      state.error = false;
    },
    [getMasterSettingById.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Update Master Setting
    [updateMasterSetting.pending]: (state) => {
      state.loading = true;
    },
    [updateMasterSetting.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateMasterSettingStatus.data = action.payload;
      state.error = false;
    },
    [updateMasterSetting.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Update Subscription Remarks
    [updateSubscriptionRemarks.pending]: (state) => {
      state.loading = true;
    },
    [updateSubscriptionRemarks.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateSubscriptionRemarksStatus.data = action.payload;
      state.error = false;
    },
    [updateSubscriptionRemarks.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Update Subscription Status
    [updateSubscriptionStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateSubscriptionStatus.fulfilled]: (state, action) => {
      state.loading = false;
      state.updateSubscriptionStatusStatus.data = action.payload;
      state.error = false;
    },
    [updateSubscriptionStatus.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});
const { reducer } = masterSettingSlice;
export default reducer;
