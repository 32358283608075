import React, { useEffect, useState } from "react";
import CustomTable from "../../../commons/CustomTable";
import Toggles from "../../../commons/Toggles";
import { useDispatch, useSelector } from "react-redux";
import {
  getSetting,
  updateSubscriptionRemarks,
  updateSubscriptionStatus,
} from "../../../redux/masterSetting/slice";
import { Close } from "../../../assets/svg/AllSvg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TextareaWithLabel } from "../../../components/micro";
import Action from "../../../commons/Action";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showRemarkPopup, serShowRemarkPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selector
  const { generalSettingList, generalSettingLoading } = useSelector(
    (store) => ({
      // profile
      generalSettingList: store?.masterSettingData?.getSettingStatus?.data,
      generalSettingLoading: store?.masterSettingData?.loading,
    })
  );
  useEffect(() => {
    dispatch(
      getSetting({
        settingType: "general_setting",
        sub_type: "subscription",
        pageCount: pageCount,
        pageLimit: pageLimit,
      })
    );
  }, [dispatch, pageCount, pageLimit]);

  // Update Status
  const updateStatus = (element) => {
    dispatch(
      updateSubscriptionStatus({
        id: element?.id,
        data: {
          status: element?.status === "inactive" ? "active" : "inactive",
        },
      })
    ).then((res) => {
      if (res.type === "updateSubscriptionStatus/fulfilled") {
        dispatch(
          getSetting({
            settingType: "general_setting",
            sub_type: "subscription",
            pageCount: pageCount,
            pageLimit: pageLimit,
          })
        );
      }
    });
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          No.
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Services
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Durations
        </th>
        {/* <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Price
        </th> */}
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Remarks
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(generalSettingList?.data || [])?.map((element, index) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">
              {(generalSettingList?.current_page - 1) *
                generalSettingList?.per_page +
                index +
                1}
            </td>
            <td className="px-5 py-3">{element?.service_name}</td>
            <td className="px-5 py-3">{element?.durations}</td>
            {/* <td className="px-5 py-3">{element?.price}</td> */}
            <td className="px-5 py-3">
              <Toggles
                checked={element?.status === "inactive" ? false : true}
                toggleFor={element?.id}
                onChange={() => {
                  updateStatus(element);
                }}
              />
            </td>
            <td className="px-5 py-3">
              <button
                type="button"
                className="text-theme border-b border-theme"
                onClick={() => {
                  setSubscriptionData(element);
                  serShowRemarkPopup(true);
                }}
              >
                View
              </button>
            </td>
            <td className="flex px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/edit-subscription", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };
  return (
    <div>
      <CustomTable
        columnHeaders={<ColumnHeaders />}
        dataRows={<DataRows />}
        data={generalSettingList}
        overflowVisibleLg={true}
        // loader
        loading={generalSettingLoading}
        showPagination={true}
        // showLimit
        setPaginationCurrentLimit={setPageLimit}
        paginationCurrentLimit={pageLimit}
        // paginationData
        total={generalSettingList?.total}
        current={pageCount}
        paginationData={(crPage) => setPageCount(crPage)}
      />
      {/* Remark Popup */}
      {showRemarkPopup && (
        <div
          className="model"
          onClick={() => {
            serShowRemarkPopup(false);
          }}
        >
          <div
            className="bg-white drop-shadow-lg rounded-lg w-full max-w-xl min-h-36 py-5 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="text-xl font-ebGaramond font-semibold w-full border-b pb-3 mb-4">
              {subscriptionData?.service_name} Remarks
            </p>
            <Close
              className="cursor-pointer absolute top-5 right-5"
              onClick={() => {
                serShowRemarkPopup(false);
              }}
            />
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  remarks: subscriptionData?.remarks ?? "",
                }}
                validationSchema={Yup.object().shape({
                  remarks: Yup.string().required("Please Enter Remarks"),
                })}
                onSubmit={(values) => {
                  dispatch(
                    updateSubscriptionRemarks({
                      id: subscriptionData?.id,
                      data: values,
                    })
                  ).then((res) => {
                    serShowRemarkPopup(false);
                    if (res.type === "updateSubscriptionRemarks/fulfilled") {
                      dispatch(
                        getSetting({
                          settingType: "general_setting",
                          sub_type: "subscription",
                          pageCount: pageCount,
                          pageLimit: pageLimit,
                        })
                      );
                    }
                  });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <TextareaWithLabel
                      className={
                        errors.remarks && touched.remarks
                          ? ` input-error `
                          : ` border `
                      }
                      idFromName="remarks"
                      label="Remarks"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.remarks}
                      errors={errors.remarks && touched.remarks}
                      errorsText={errors.remarks}
                    />
                    <div className="flex justify-end gap-4 pt-3">
                      <button type="submit" className="btn-theme uppercase">
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Subscription;
