import React, { useState } from "react";
import AccountDetails from "./AccountDetails";
import { AddButton } from "../../../components/micro";

const AccountInformation = () => {
  const [accountDetailsList, setAccountDetailsList] = useState([]);

  // Remove Account
  const removeAccount = (index) => {
    setAccountDetailsList([
      ...accountDetailsList.slice(0, index),
      ...accountDetailsList.slice(index + 1),
    ]);
  };

  // Update Account Details
  const updateAccountDetailsList = (
    index,
    company_name,
    company_role,
    system_role
  ) => {
    const updatedAccount = accountDetailsList?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          company_name: company_name,
          company_role: company_role,
          system_role: system_role,
        };
      } else {
        return item;
      }
    });
    setAccountDetailsList(updatedAccount);
  };

  const company_nameList = [
    { value: "companyOne", label: "companyOne" },
    { value: "companyTwo", label: "companyTwo" },
  ];
  const company_roleList = [
    { value: "companyRoleOne", label: "companyRoleOne" },
    { value: "companyRoleTwo", label: "companyRoleOne" },
  ];
  const system_roleList = [
    { value: "systemOne", label: "systemOne" },
    { value: "systemTwo", label: "systemTwo" },
  ];

  return (
    <>
      <div className="space-y-5 mb-5">
        {accountDetailsList?.map((element, index) => {
          return (
            <AccountDetails
              element={element}
              className={
                index % 2 === 0 ? "bg-[#0000000A]" : "bg-light_secondary_shades"
              }
              index={index}
              removeAccount={removeAccount}
              updateAccountDetailsList={updateAccountDetailsList}
              company_nameList={company_nameList}
              company_roleList={company_roleList}
              system_roleList={system_roleList}
            />
          );
        })}
      </div>
      <div className="flex justify-center py-3">
        <AddButton
          value="Add Company"
          onClick={(e) => {
            setAccountDetailsList([
              ...accountDetailsList,
              {
                companyName: "",
                companyRole: "",
                systemName: "",
              },
            ]);
          }}
        />
      </div>
      <div className="flex justify-end gap-4 pt-3">
        <button type="button" className="btn-outline uppercase">
          Cancel
        </button>
        <button type="submit" className="btn-theme uppercase">
          Next
        </button>
      </div>
    </>
  );
};

export default AccountInformation;
