import React, { useState } from "react";
import { PDFDoc } from "../assets/svg/AllSvg";
import Action from "./Action";
import EditDocument from "../pages/companies/viewCompany/editDocuments";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteDoc } from "../redux/folder&documents/slice";
import ConfirmationModel from "./ConfirmationModel";

const PdfDocument = ({
  documentName,
  uploadTime,
  cardClasses,
  companyByIdData,
  documentId,
  fetchDocuments,
}) => {
  const dispatch = useDispatch();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRenameClick = () => {
    setEditModalOpen(true);
  };

  const handleDeleteClick = () => {
    setConfirmModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(deleteDoc(documentId));
      if (res.type === "deleteDoc/fulfilled") {
        toast.success(res.payload.message);
      }
    } finally {
      setIsLoading(false);
      setConfirmModalOpen(false);
    }
  };

  const handleEditSuccess = async () => {
    setIsLoading(true);
    try {
      await fetchDocuments(); 
      toast.success("Document edited successfully!");
    } catch (error) {
      toast.error("Failed to refresh documents.");
    } finally {
      setIsLoading(false);
      setEditModalOpen(false); 
    }
  };

  return (
    <div
      className={`card bg-light_secondary space-y-2 relative ${cardClasses}`}
    >
      <div className={"flex justify-between items-center"}>
        <div className="flex items-center">
          <PDFDoc className="mr-3" />
          <p className="text-black font-normal text-sm">{documentName?.name}</p>
        </div>
        <button className="absolute top-0 -right-2 p-2">
          <Action
            optionList={
              <>
                <button
                  className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={handleRenameClick}
                >
                  Rename File
                </button>
                <button
                  className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                  onClick={handleDeleteClick}
                >
                  Delete
                </button>
              </>
            }
          />
        </button>
      </div>
      {uploadTime && (
        <p className="text-center text-light_grey font-semibold text-xs leading-normal">
          Uploaded on <span className="block">“{uploadTime}”</span>
        </p>
      )}
      <EditDocument
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        documentName={documentName?.name}
        documentId={documentId}
        companyByIdData={companyByIdData}
        onSuccess={handleEditSuccess}
      />
      {isConfirmModalOpen && (
        <ConfirmationModel
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleConfirmDelete}
          confirmText="Delete Document"
          isLoading={isLoading}
        />
      )}
      {isLoading && <div className="loader">Loading...</div>}
    </div>
  );
};

export default PdfDocument;
