// eslint-disable-next-line import/no-anonymous-default-export
export default {
  loading: false,
  error: false,
  companyExistStatus: { loading: false, data: null },
  businessActivitiesStatus: { data: null, loading: false },
  AddressStatus: { data: null },
  createAddressStatus: { data: null },
  employmentSupportStatus: { data: null },
  employmentPassStatus: { data: null },
  CreateCompanyStatus: { data: null },
  companyDataStatus: { data: null },
  deleteDirectorShareholderStatus: { data: null },
  existingUsersStatus: { data: null, loading: false },
  addDirectorShareholderStatus: { data: null, loading: false },
  getNomineeDirectorsDetailsStatus: { data: null },
  getCorporateSecretaryStatus: { data: null },
  companiesListStatus: { data: null },
  getCompanyByIdStatus: { data: null },
  updateCompanyStatus: { data: null },
  getSalesByCompanyIdStatus: { data: null, loading: false },
  getTransactionsByIdStatus: { data: null },
};
