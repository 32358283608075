import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import { toast } from "react-toastify";
import initialStates from "./state";

// Get File  List
export const getFileList = createAsyncThunk(
  "getFileList",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`folders?company_id=${id}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create File  List
export const createFile = createAsyncThunk(
  "createFile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`folders`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFileDetail = createAsyncThunk(
  "getFileDetail",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.get(`documents?folder_id=${id}`);
      return response.data.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Document
export const createDocument = createAsyncThunk(
  "createDocument",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.post(`documents`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Create Document
export const updateDocument = createAsyncThunk(
  "createDocument",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.put(`documents/${id}`, data);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete Doc
export const deleteDoc = createAsyncThunk(
  "deleteDoc",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`documents/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete flie
export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (id, { rejectWithValue }) => {
    try {
      const response = await AxiosApi.delete(`folders/${id}`);
      toast.success(response.data.msg);
      return response.data;
    } catch (error) {
      if (!error) {
        throw error.response;
      }
      toast.error(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const fileDataSlice = createSlice({
  name: "fileData",
  initialState: initialStates,
  extraReducers: {
    // get File List
    [getFileList.pending]: (state) => {
      state.loading = true;
    },
    [getFileList.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFileListStatus.data = action.payload;
      state.error = false;
    },
    [getFileList.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Upload File
    [createFile.pending]: (state) => {
      state.loading = true;
    },
    [createFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.uploadStatus.data = action.payload;
      state.error = false;
    },
    [createFile.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // get File detail
    [getFileDetail.pending]: (state) => {
      state.loading = true;
    },
    [getFileDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.getFileDetailStatus.data = action.payload;
      state.error = false;
    },
    [getFileDetail.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
    // Upload Document
    [createDocument.pending]: (state) => {
      state.loading = true;
    },
    [createDocument.fulfilled]: (state, action) => {
      state.loading = false;
      state.createDocumentStatus.data = action.payload;
      state.error = false;
    },
    [createDocument.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // Delete Doc
    [deleteDoc.pending]: (state) => {
      state.loading = true;
    },
    [deleteDoc.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteDocStatus.data = action.payload;
      state.error = false;
    },
    [deleteDoc.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },

    // Delete File
    [deleteFile.pending]: (state) => {
      state.loading = true;
    },
    [deleteFile.fulfilled]: (state, action) => {
      state.loading = false;
      state.deleteFileStatus.data = action.payload;
      state.error = false;
    },
    [deleteFile.rejected]: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

const { reducer } = fileDataSlice;
export default reducer;
