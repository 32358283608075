import React from "react";
import PhoneInput from "react-phone-number-input";
import { Plus } from "../../assets/svg/AllSvg";
import { InputError } from "../../commons/MicroComponents";
import { InfoCircle } from "iconsax-react";
import ReactSelect from "react-select";

export const Icon = ({ type, iconName, size, color }) => {
  const IconName = require(`iconsax-react`)[iconName];
  return <IconName size={size} color={color} />;
};
export const StartDateOfMonth = () => {
  var currentDate = new Date();
  const startDateOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  return startDateOfMonth;
};
export const DefaultPhoneInput = ({
  value,
  onChange,
  defaultCountry = "IN",
  ...res
}) => {
  return (
    <PhoneInput
      international
      countryCallingCodeEditable={false}
      defaultCountry={defaultCountry}
      value={value}
      onChange={onChange}
      {...res}
    />
  );
};
export const Input = ({ className, ...res }) => {
  return <input className={className} {...res} />;
};
export const Badge = ({ className, value, ...res }) => {
  return (
    <p className={`border rounded-full px-3 py-2 ${className}`} {...res}>
      {value}
    </p>
  );
};
export const AddButton = ({ className, value, ...res }) => {
  return (
    <div className={`flex justify-center py-3 ${className}`}>
      <button className="plus-btn" type="button" {...res}>
        <Plus className="fill-black mr-2" />
        <p>{value}</p>
      </button>
    </div>
  );
};
export const InputWithLabel = ({
  className,
  idFromName,
  label,
  type,
  errors,
  inputClass,
  errorsText,
  icon,
  ...res
}) => {
  return (
    <div>
      <div
        className={`${className} relative bg-white transition duration-300 rounded`}
      >
        <Input
          type={type}
          id={idFromName}
          name={idFromName}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClass}`}
          placeholder={" "}
          {...res}
        />
        <label
          htmlFor={idFromName}
          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
        >
          {label}
        </label>
        {icon && <>{icon}</>}
      </div>
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
export const TextareaWithLabel = ({
  className,
  idFromName,
  label,
  errors,
  errorsText,
  inputClass,
  icon,
  ...res
}) => {
  return (
    <div>
      <div
        className={`${className} relative bg-white border-secondary_grey transition duration-300 rounded`}
      >
        <textarea
          id={idFromName}
          name={idFromName}
          className={`block px-2.5 pb-2.5 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer ${inputClass}`}
          placeholder={" "}
          {...res}
        ></textarea>
        <label
          htmlFor={idFromName}
          className="absolute text-light_grey pointer-events-none text-base bg-white duration-300 transform -translate-y-5 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
        >
          {label}
        </label>
      </div>
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
export const RadioButton = ({ className, idFrom, name, label, ...res }) => {
  return (
    <label
      htmlFor={idFrom}
      className={`flex items-center space-x-2 ${className}`}
    >
      <input
        type="radio"
        name={name}
        id={idFrom}
        className="w-0 h-0 peer"
        {...res}
      />
      <span className="w-5 h-5 border-2 inline-block rounded-full peer-checked:border-theme relative peer-checked:after:absolute peer-checked:after:top-1/2 peer-checked:after:left-1/2 peer-checked:after:rounded-full peer-checked:after:w-3 peer-checked:after:h-3 peer-checked:after:bg-theme peer-checked:after:-translate-x-1/2 peer-checked:after:-translate-y-1/2"></span>
      <p>{label}</p>
    </label>
  );
};
export const InformationTag = ({ type, text, ...res }) => {
  return (
    <div
      className={`flex items-center ${
        type === "success"
          ? "text-success bg-green-100"
          : type === "error"
          ? "text-red bg-red-100"
          : ""
      } space-x-3 rounded px-5 py-3`}
      {...res}
    >
      <InfoCircle size="20" />
      <span className="font-normal">{text}</span>
    </div>
  );
};

export const CheckBox = ({
  className,
  idFrom,
  name,
  label,
  labelClass,
  ...res
}) => {
  return (
    <label
      htmlFor={idFrom}
      className={`flex items-center space-x-2 ${className}`}
    >
      <input
        type="checkbox"
        name={name}
        id={idFrom}
        className="w-0 h-0 peer"
        {...res}
      />
      <span
        className={`max-w-5 w-full h-5 border-2 border-secondary_grey inline-block rounded peer-checked:border-theme relative peer-checked:after:absolute peer-checked:after:top-1/2 peer-checked:after:left-1/2  peer-checked:after:w-full peer-checked:after:h-[calc(100%+3px)] peer-checked:after:bg-check_img peer-checked:after:-translate-x-1/2 peer-checked:after:-translate-y-1/2`}
      ></span>
      <p className={labelClass}>{label}</p>
    </label>
  );
};
export const Tooltip = ({
  tooltipLabel,
  label,
  labelClass,
  tooltipLabelClass,
  ...res
}) => {
  return (
    <div className="relative group" {...res}>
      <button className={labelClass}>{label}</button>

      <div
        className={`absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:block bg-light_primary text-white text-sm px-4 py-2 rounded ${tooltipLabelClass}`}
      >
        {tooltipLabel}
        <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-0 h-0 border-t-8 border-t-light_primary border-l-8 border-l-transparent border-r-8 border-r-transparent"></div>
      </div>
    </div>
  );
};

export const SelectBox = ({
  options,
  placeholder,
  errors,
  errorsText,
  className,
  paddingY,
  paddingX,
  ...res
}) => {
  return (
    <div>
      <ReactSelect
        className={`text-black p-0 ${className}`}
        placeholder={placeholder}
        options={options}
        {...res}
        styles={{
          control: (base) => ({
            ...base,
            border: `1px solid ${errors ? "red" : "#0000003B"}`,
            borderRadius: "0.25rem",
            padding: `${paddingY ?? "6px"} ${paddingX ?? "4px"}`,
            boxShadow: "none",
            "&:hover": {
              border: "1px solid #0000003B",
            },
          }),
        }}
      />
      {errors ? <InputError errorTitle={errorsText} /> : null}
    </div>
  );
};
