import React from "react";
import { User } from "../assets/svg/AllSvg";
import moment from "moment/moment";
import { authBgImage } from "../assets/image";
import Action from "./Action";

const ReceiveMsg = ({ isActive, img, element }) => {
  return (
    <>
      <div className="flex items-start space-x-3">
        <div className="relative w-9 h-9 bg-grey rounded-full flex justify-center items-center">
          <User pathClass={"fill-white"} fillOpacity={1} />
          {isActive && (
            <span className="absolute bottom-0 right-0 border-2 border-white w-2.5 h-2.5 bg-green-600 rounded-full"></span>
          )}
          {img && (
            <img
              src={img}
              alt="profile"
              className="w-full h-full object-cover rounded-full"
            />
          )}
        </div>
        <div className="space-y-2">
          <div className="messageContent max-w-52 space-y-2">
            {element?.type === "text" ? (
              <p className="messageText bg-sub_grey px-3 py-2">
                {element.content}
              </p>
            ) : element?.type === "document" ? (
              <div className="relative">
                <img src={authBgImage} alt="img" className="w-full h-52" />
                <Action
                  optionList={
                    <>
                      <li className="flex gap-2 items-center px-4 py-1.5 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Download
                      </li>
                      <li className="flex gap-2 items-center px-4 py-1.5 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer">
                        Delete
                      </li>
                    </>
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>

          <p className="text-grey text-xs text-right">
            {moment(element.updated_at).format("dd/mm/YYYY HH:MM")}
          </p>
        </div>
      </div>
    </>
  );
};

export default ReceiveMsg;
