// src/firebase.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBBINgdnL2tjhJ78yFts4erGT5KUY-b87I",
  authDomain: "ei-biz-dbb6b.firebaseapp.com",
  projectId: "ei-biz-dbb6b",
  storageBucket: "ei-biz-dbb6b.appspot.com",
  messagingSenderId: "496990153639",
  appId: "1:496990153639:web:25a71ec9e48a4b42c32482",
  measurementId: "G-083NTGT2LE",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken };
