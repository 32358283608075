import React, { useState } from "react";
import Breadcrumb from "../../commons/Breadcrumb";
import { FileDownload, Plus } from "../../assets/svg/AllSvg";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import DebounceSearch from "../../commons/DebounceSearch";
import CustomTable from "../../commons/CustomTable";
import Action from "../../commons/Action";
import Toggles from "../../commons/Toggles";
import { Badge } from "../../components/micro";

const Customers = () => {
  const [activeTab, setActiveTab] = useState("Active");
  const [selectedTagStatus, setSelectedTagStatus] = useState();
  const [selectedKyc, setSelectedKyc] = useState();
  const [pageLimit, setPageLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [customersData, setCustomersData] = useState({});
  const [showConfirmArchivePopup, setShowConfirmArchivePopup] = useState(false);
  const navigate = useNavigate();

  // Active and Archived Tab
  const tabs = [
    {
      label: "Active",
      content: "",
    },
    { label: "Archived", content: "" },
  ];

  // Tag Status Dropdown
  const tagStatusDropdown = [
    { value: "tagged", label: "Tagged" },
    { value: "untagged", label: "Untagged" },
  ];

  //   KYC Dropdown
  const kycDropdown = [
    { value: "low_risk", label: "Low Risk" },
    { value: "mid_risk", label: "Mid Risk" },
    { value: "high_risk", label: "High Risk" },
    { value: "pending", label: "Pending" },
  ];

  // Table Data
  const customersList = {
    data: [
      {
        customersName: "Customers Name 1",
        id: "S0001",
        role: "Sub-Admin",
        mobile: "+65 9988 3344",
        email: "test@email.com",
        status: true,
        tagStatus: "tagged",
        kyc: "lowRisk",
      },
      {
        customersName: "Customers Name 2",
        id: "S0002",
        role: "Finance",
        mobile: "+65 9988 5566",
        email: "testing@gmail.com",
        tagStatus: "untagged",
        status: false,
        kyc: "midRisk",
      },
      {
        customersName: "Customers Name 3",
        id: "S0003",
        role: "Finance",
        mobile: "+65 9988 5566",
        email: "testing@gmail.com",
        tagStatus: "untagged",
        status: false,
        kyc: "highRisk",
      },
      {
        customersName: "Customers Name 4",
        id: "S0004",
        role: "Finance",
        mobile: "+65 9988 5566",
        email: "testing@gmail.com",
        tagStatus: "untagged",
        status: false,
        kyc: "pending",
      },
    ],
    current_page: 1,
    per_page: 10,
    last_page: 1,
    total: 4,
  };

  // Table Heading
  const ColumnHeaders = () => {
    return (
      <>
        <th
          scope="col"
          className="px-5 py-3 border-b font-semibold text-left bg-primary_shades_light whitespace-nowrap rounded-tl"
        >
          ID
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light"
        >
          Name
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Email
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Mobile
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          KYC
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Tag Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-5 py-2 border-b font-semibold text-left bg-primary_shades_light rounded-tr"
        >
          Action
        </th>
      </>
    );
  };
  // Table Row
  const DataRows = () => {
    return (
      <>
        {(customersList?.data || [])?.map((element) => (
          <tr
            className={"border-b text-sm hover:bg-secondary_light_shades"}
            key={element?.id}
          >
            <td className="px-5 py-3">{element?.id}</td>
            <td className="px-5 py-3">{element?.customersName}</td>
            <td className="px-5 py-3">{element?.email}</td>
            <td className="px-5 py-3">{element?.mobile}</td>
            <td className="px-5 py-3">
              {
                <div className="flex justify-start">
                  <Badge
                    className={`capitalize ${
                      element?.kyc === "lowRisk"
                        ? "border-green-700 text-green-700"
                        : element?.kyc === "midRisk"
                        ? "border-orange-700 text-orange-700"
                        : element?.kyc === "highRisk"
                        ? "border-red-700 text-red-700"
                        : element?.kyc === "pending"
                        ? "text-black"
                        : ""
                    }`}
                    value={
                      element?.kyc === "lowRisk"
                        ? "Low Risk"
                        : element?.kyc === "midRisk"
                        ? "Mid Risk"
                        : element?.kyc === "highRisk"
                        ? "High Risk"
                        : element?.kyc === "pending"
                        ? "Pending"
                        : ""
                    }
                  />
                </div>
              }
            </td>
            <td className="px-5 py-3">
              <div className="flex justify-start">
                {
                  <Badge
                    className={`text-white capitalize ${
                      element?.tagStatus === "tagged"
                        ? "border-green-700 bg-green-700"
                        : element?.tagStatus === "untagged"
                        ? "border-red-700 bg-red-700"
                        : ""
                    }`}
                    value={element?.tagStatus}
                  />
                }
              </div>
            </td>
            <td className="px-5 py-3">
              <Toggles toggleFor={element?.id} checked={element?.status} />
            </td>
            <td className="flex justify-start px-5 py-3">
              <Action
                optionList={
                  <>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() =>
                        navigate("/create-edit-customers", {
                          state: {
                            element: element,
                          },
                        })
                      }
                    >
                      View/Edit
                    </li>
                    <li
                      className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                      onClick={() => {
                        setCustomersData(element);
                        setShowConfirmArchivePopup(true);
                      }}
                    >
                      Archive
                    </li>
                  </>
                }
              />
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="space-y-5">
        <div className="flex justify-between items-start">
          <div>
            <h6 className="font-ebGaramond font-semibold text-2xl mb-3">
              Customers
            </h6>
            <Breadcrumb
              breadCrumbParent={"Home"}
              breadCrumbActive={"Customers"}
              breadCrumbTitleKey={"/"}
              classes=""
            />
          </div>
          <button
            className="btn-theme flex items-center uppercase"
            onClick={() => {
              navigate("/create-edit-customers");
            }}
          >
            <Plus className="mr-2" /> Add Customers
          </button>
        </div>
        <div className="flex justify-end">
          {tabs.map((tab, index) => {
            return (
              <button
                key={tab.label}
                className={`transition uppercase duration-300 ${
                  index === 0 ? "!rounded-l !rounded-r-none" : ""
                }
            ${index === tabs.length - 1 ? "!rounded-r !rounded-l-none" : ""} ${
                  activeTab === tab.label ? "btn-theme" : "btn-light_primary"
                }`}
                onClick={() => setActiveTab(tab.label)}
              >
                {tab.label}
              </button>
            );
          })}
        </div>
        <div className="flex justify-between">
          <div className="flex items-center space-x-3">
            <DebounceSearch
              search={searchValue}
              handleChange={setSearchValue}
              placeholder={"Search..."}
            />
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="tagStatusDropdown"
              placeholder="Select Tag Status"
              options={tagStatusDropdown}
              isLoading={false}
              getOptionValue={(selectedTagStatus) => selectedTagStatus.value}
              value={selectedTagStatus}
              onChange={(e) => {
                setSelectedTagStatus(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
            <ReactSelect
              className={"w-full max-w-xs text-black p-0"}
              id="kyc"
              placeholder="Select KYC"
              options={kycDropdown}
              isLoading={false}
              getOptionValue={(selectedKyc) => selectedKyc.value}
              value={selectedKyc}
              onChange={(e) => {
                setSelectedKyc(e);
              }}
              styles={{
                control: (base) => ({
                  ...base,
                  border: `1px solid #cdddeb`,
                  borderRadius: "0.3rem",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  boxShadow: "none",
                  "&:hover": {
                    border: "1px solid #cdddeb",
                  },
                }),
              }}
            />
          </div>
          <button className="btn-outline flex items-center uppercase">
            <FileDownload width="14" height="17" className="mr-3" />
            Export CSV
          </button>
        </div>
        <div className="w-full bg-white border rounded">
          <CustomTable
            columnHeaders={<ColumnHeaders />}
            dataRows={<DataRows />}
            data={customersList}
            overflowVisibleLg={true}
            // loader
            loading={false}
            showPagination={true}
            // showLimit
            setPaginationCurrentLimit={setPageLimit}
            paginationCurrentLimit={pageLimit}
            // paginationData
            total={customersList?.total}
            current={pageCount}
            paginationData={(crPage) => setPageCount(crPage)}
          />
        </div>
      </div>
      {showConfirmArchivePopup && (
        <div
          className="model"
          onClick={() => {
            setShowConfirmArchivePopup(false);
          }}
        >
          <div
            className="bg-white flex flex-col items-center justify-center drop-shadow-lg rounded-lg w-full max-w-sm min-h-40 py-8 px-6 mx-auto relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2 className="text-2xl font-ebGaramond mb-4">Confirm Archive?</h2>
            <div className="space-x-2">
              <button
                className="btn-outline text-xs uppercase"
                onClick={() => {
                  setShowConfirmArchivePopup(false);
                }}
              >
                Cancel
              </button>
              <button
                className="btn-theme text-xs uppercase"
                onClick={() => {
                  setShowConfirmArchivePopup(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Customers;
