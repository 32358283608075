import React, { useState } from "react";
import DebounceSearch from "../../commons/DebounceSearch";
import { UpArrow, User } from "../../assets/svg/AllSvg";
import { authBgImage } from "../../assets/image";
import AvatarUser from "../../commons/AvatarUser";

const ClientsList = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showClients, setShowClients] = useState(true);
  const [showGeneralChat, setShowGeneralChat] = useState(true);
  const [activeTab, setActiveTab] = useState("Active");

  const tabs = [{ label: "Active" }, { label: "Archive" }];

  const clients_list = [
    {
      name: "Name 1",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
    },
    {
      name: "Name 2",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: false,
    },
    {
      name: "Name 3",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: false,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
    {
      name: "Name 4",
      company_name: "Company Name",
      date: "dd/mm/YYYY HH:MM",
      isActive: true,
      img: authBgImage,
    },
  ];

  return (
    <div className="space-y-3 pr-4 border-r">
      <p className="font-ebGaramond text-theme text-xl">Messages</p>
      <div className="flex border border-theme rounded overflow-hidden">
        {tabs.map((tab, index) => (
          <button
            key={tab.label}
            className={`py-2 px-4 uppercase text-center text-base text-theme font-semibold w-full transition duration-300 ${
              activeTab === tab.label ? "bg-theme/30" : ""
            } ${index === 0 && "border-r border-theme"}`}
            onClick={() => setActiveTab(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <DebounceSearch
        search={searchValue}
        handleChange={setSearchValue}
        placeholder={"Search..."}
      />
      <button
        className="text-theme flex items-center justify-between w-full px-4"
        onClick={() => {
          setShowClients(!showClients);
        }}
      >
        <p>Clients</p>
        {!showClients ? <UpArrow /> : <UpArrow className="rotate-180" />}
      </button>
      {showClients && (
        <div className="max-h-64 overflow-auto">
          {clients_list?.map((element, index) => {
            return (
              <AvatarUser
                className={
                  "hover:bg-light_secondary_shades rounded-xl px-4 py-3"
                }
                isActive={element?.isActive}
                img={element?.img}
                name={element?.name}
                company_name={element?.company_name}
                date={element?.date}
                key={index}
              />
            );
          })}
        </div>
      )}
      <button
        className="text-theme flex items-center justify-between w-full"
        onClick={() => {
          setShowGeneralChat(!showGeneralChat);
        }}
      >
        <p>General Chat</p>
        {!showGeneralChat ? <UpArrow /> : <UpArrow className="rotate-180" />}
      </button>
      {showGeneralChat && (
        <div className="max-h-56 overflow-auto space-y-3">
          {clients_list?.map((element, index) => {
            return (
              <AvatarUser
                className={
                  "hover:bg-light_secondary_shades rounded-xl px-4 py-3"
                }
                isActive={element?.isActive}
                img={element?.img}
                name={element?.name}
                company_name={element?.company_name}
                date={element?.date}
                key={index}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClientsList;
