import React from "react";
import { Badge, InputWithLabel } from "../../../components/micro";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createDocument, updateDocument } from "../../../redux/folder&documents/slice";
import { useDispatch } from "react-redux";

const EditDocument = ({
  isOpen,
  onClose,
  companyByIdData,
  documentName,
  documentId,
  onSuccess
}) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      documentName: documentName || "",
    },
    validationSchema: Yup.object({
      documentName: Yup.string().required("Document name is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.documentName,
      };
      dispatch(updateDocument({ id: documentId, data: payload }));
      formik.resetForm();
      onSuccess();
      onClose();
    },
    enableReinitialize: true,
  });

  if (!isOpen) return null;

  return (
    <div className="model">
      <div className="bg-white flex flex-col justify-center drop-shadow-lg rounded-lg w-full max-w-lg min-h-40 py-6 px-6 mx-auto relative">
        <h2 className="text-2xl text-theme font-ebGaramond border-b border-secondary_grey w-full text-center pb-3 mb-4">
          Rename File
        </h2>
        <div className="flex space-x-3 mb-5">
          <p className="text-xl font-ebGaramond text-start">
            {companyByIdData?.name}
          </p>
          <Badge
            className={`text-white border-success bg-success font-medium text-sm !py-1`}
            value={"Live"}
          />
        </div>

        <form onSubmit={formik.handleSubmit}>
          <InputWithLabel
            label={"File’s Name"}
            type={"text"}
            className="border"
            idFromName="documentName"
            onChange={formik.handleChange}
            value={formik.values.documentName}
            onBlur={formik.handleBlur}
          />
          {formik.touched.documentName && formik.errors.documentName && (
            <div className="text-red-600 text-sm">
              {formik.errors.documentName}
            </div>
          )}

          <div className="flex w-full mt-5">
            <button
              type="button"
              className="btn-outline mr-2 w-full"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`w-full ${
                !formik.isValid || formik.values.documentName.trim() === ""
                  ? "bg-gray-400"
                  : "btn-theme"
              }`}
              disabled={
                !formik.isValid || formik.values.documentName.trim() === ""
              }
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDocument;
