import React from "react";
import { ImgSearchIcon, Send } from "../../assets/svg/AllSvg";
import AvatarUser from "../../commons/AvatarUser";
import ReceiveMsg from "../../commons/ReceiveMsg";
import SendMsg from "../../commons/SendMsg";
import Action from "../../commons/Action";
import { useNavigate } from "react-router-dom";

const ChatContainer = ({ element }) => {
  const navigate = useNavigate();
  const chat = [
    {
      isSender: true,
      type: "text",
      content: "Hey",
      created_at: "2024-07-02T05:48:17.000000Z",
      updated_at: "2024-07-02T05:48:17.000000Z",
    },
    {
      isSender: true,
      type: "document",
      content: "text",
      created_at: "2024-07-02T05:48:17.000000Z",
      updated_at: "2024-07-02T05:48:17.000000Z",
    },
    {
      isSender: false,
      type: "text",
      content: "Hey",
      created_at: "2024-07-02T05:48:17.000000Z",
      updated_at: "2024-07-02T05:48:17.000000Z",
    },
    {
      isSender: true,
      type: "text",
      content: "Nice",
      created_at: "2024-07-02T05:48:17.000000Z",
      updated_at: "2024-07-02T05:48:17.000000Z",
    },
    {
      isSender: false,
      type: "document",
      content: "You Too",
      created_at: "2024-07-02T05:48:17.000000Z",
      updated_at: "2024-07-02T05:48:17.000000Z",
    },
  ];
  return (
    <div className="col-span-3 border-r flex flex-col justify-between px-5">
      <div className="flex justify-between items-center border-b pb-3">
        <AvatarUser
          className={""}
          isActive={element?.isActive}
          img={element?.img ?? ""}
          name={element?.name ?? "Name"}
          company_name={element?.company_name ?? "Company Name"}
          date={element?.date ?? "dd/mm/yyyy HH:MM"}
        />
        <Action
          optionList={
            <>
              <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() =>
                  navigate("/chat", {
                    state: {
                      element: element,
                    },
                  })
                }
              >
                Archive
              </li>
              <li
                className="flex gap-2 items-center px-6 py-2 bg-white hover:bg-secondary_light_shades transition duration-300 cursor-pointer"
                onClick={() => {
                  // setCompanyData(element);
                  // setShowConfirmArchivePopup(true);
                }}
              >
                Block
              </li>
            </>
          }
        />
      </div>
      <div className="h-[calc(100vh-350px)] overflow-auto py-3 space-y-2">
        {chat?.map((element, key) => {
          return (
            <>
              {element?.isSender ? (
                <ReceiveMsg element={element} />
              ) : (
                <SendMsg element={element} />
              )}
            </>
          );
        })}
      </div>
      <div className="py-2">
        <div
          className={`relative bg-white border-secondary_grey transition duration-300 rounded border`}
        >
          <textarea
            id={"message"}
            name={"message"}
            className="block px-2.5 pb-2.5 pr-20 pt-4 w-full text-sm error-forms text-gray-900 bg-transparent rounded border-1 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder={" "}
            rows={1}
          ></textarea>
          <div className="absolute top-1/2 right-0 -translate-y-1/2 -translate-x-3 flex items-center">
            <button className="relative p-2 overflow-hidden">
              <ImgSearchIcon />
              <input
                className="absolute top-0 left-0 w-full h-full opacity-0"
                type="file"
                // onChange={handleFileChange}
                accept="jpeg, jpg, pdf, png"
              />
            </button>
            <button type="submit" className="p-2">
              <Send />
            </button>
          </div>
          <label
            htmlFor={"message"}
            className="absolute text-light_grey pointer-events-none text-xs bg-white duration-300 transform -translate-y-1/2 scale-75 top-2 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-5 left-1"
          >
            Message
          </label>
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
