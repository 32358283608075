import React from "react";
import { FileUpload } from "../assets/svg/AllSvg";

const FileUploadChoose = ({handleFileChange, className}) => {
  return (
    <>
      <div className={`relative flex flex-col items-center justify-center border-2 border-dashed cursor-pointer rounded-2xl overflow-hidden px-4 py-8 ${className}`}>
        <input
          className="absolute top-0 left-0 w-full h-full opacity-0"
          type="file"
          onChange={handleFileChange}
          accept="jpeg, jpg, pdf, png"
        />
        <FileUpload width={60} height={60} className="mb-4" />
        <p className="text-base mb-2">
          Drag and Drop or{" "}
          <span className="text-[#0288d1]">choose your file</span> for upload
        </p>
        <p className="text-light_grey text-base">
          jpeg, jpg, pdf, png (max. 25mb)
        </p>
      </div>
    </>
  );
};

export default FileUploadChoose;
