import React from "react";
import { User } from "../assets/svg/AllSvg";

const AvatarUser = ({ isActive, img, name, company_name, date, className }) => {
  return (
    <>
      <div className={`flex items-center gap-3 duration-300 ${className}`}>
        <div className="relative w-9 h-9 bg-grey rounded-full flex justify-center items-center">
          <User pathClass={"fill-white"} fillOpacity={1} />
          {isActive && (
            <span className="absolute bottom-0 right-0 border-2 border-white w-2.5 h-2.5 bg-green-600 rounded-full"></span>
          )}
          {img && (
            <img
              src={img}
              alt="profile"
              className="w-full h-full object-cover rounded-full"
            />
          )}
        </div>
        <div className="text-xs space-y-1">
          <p className="text-base leading-none">{name}</p>
          <p>{company_name}</p>
          <p className="text-grey">{date}</p>
        </div>
      </div>
    </>
  );
};

export default AvatarUser;
