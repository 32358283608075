import React from "react";
import { ChatFilled, Plus } from "../../assets/svg/AllSvg";
import ClientsList from "./ClientsList";
import ChatContainer from "./ChatContainer";
import Documents from "./Documents";

const Chat = () => {
  return (
    <div className="card !rounded-2xl space-y-5">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <ChatFilled fillClass="fill-light_grey" className="w-7 h-7" />
          <p className="text-3xl font-ebGaramond">Chat</p>
        </div>
        <button className="btn-theme flex items-center space-x-3 !rounded-full">
          <Plus />
          <span>New Chat</span>
        </button>
      </div>
      <div className="grid grid-cols-5">
        <ClientsList />
        <ChatContainer />
        <Documents />
      </div>
    </div>
  );
};

export default Chat;
